import React, { useState, useCallback, useEffect } from "react"
import {
  LegacyCard,
  FormLayout,
  TextField,
  Button,
  Icon,
  Layout,
  LegacyStack,
  Popover,
  ActionList,
  Modal,
  ButtonGroup,
  Form,
  Checkbox,
  ChoiceList,
  Link,
  Text,
} from "@shopify/polaris";
import {
  ImageIcon,
  TextBlockIcon,
  TextInColumnsIcon,
  LayoutHeaderIcon,
  DeleteIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from "@shopify/polaris-icons";
import update from "immutability-helper"
import Divider from "../../../components/divider"
import SmartRules from "../offers/smartRules"
import CreateOfferSteps from "../offers/createOfferSteps"
import DocSave from "../../../components/docSave"
const AddressBlockerForm = (props) => {
  const {
    token,
    host,
    shop,
    location,
    state,
    setState,
    productPickerShow,
    setProductPickerShow,
    currencySymbol,
    isCreating,
    isEditing,
    isSubmitting,
    hasUnsavedChanges,
    handleFormSubmit,
    handleCustomContentDelete,
  } = props
  const [ruleDropDownActive, setRuleDropDownActive] = useState(false)

  const [ruleEdit, setRuleEdit] = useState(0)
  const [popoverActive, setPopoverActive] = useState(false)
  const [expanded, setExpanded] = useState(false)

  const [addressTypeSelected, setAddressTypeSelected] = useState(
    state?.rules && state?.rules.find((r) => r.ruleType === "Address type")
      ? state?.rules.find((r) => r.ruleType === "Address type").value
      : []
  )
  const handleChange = (value) => {
    setAddressTypeSelected(value)
    setState(
      update(state, {
        rules: {
          [ruleEdit]: { value: { $set: value } },
        },
      })
    )
  }
  const togglePopoverActive = () => {
    setPopoverActive((popoverActive) => !popoverActive)
    setExpanded(!expanded)
  }

  const toggleRuleDropDownActive = useCallback(
    () => setRuleDropDownActive((ruleDropDownActive) => !ruleDropDownActive),
    []
  )
  const addAddressType = () => {
    setState(
      update(state, {rules: {
        $push: [
          {
            ruleType: "Address type",
            value: [],
          },
        ],
      }})
    )
    setRuleEdit(state.rules.length)
  }
  const deleteOneContent = (index) => {
    setState(update(state, { rules: { $splice: [[index, 1]] } }))
    setRuleEdit(0)
  }
  const activator = (
    <Button onClick={toggleRuleDropDownActive} size="large">
      + Add rule
    </Button>
  )
  return (
    <Layout>
      <Layout.Section>
        {/*isCreating && (
        <div style={{ margin: "20px 0 10px -25px" }}>
          <CreateOfferSteps step={2} />
        </div>
        )*/}
        <div style={{ marginBottom: 50 }}>
          <Form disabled={!state.name} onSubmit={handleFormSubmit}>
            <FormLayout>
              <LegacyCard sectioned>
                <FormLayout>
                  <h6>
                    <Text  variant="bodyMd" as="span" fontWeight="semibold">Address blocker</Text>
                  </h6>
                  <p>Block the checkout if any of the following are true:</p>
                  {state.rules.map((rule, index) => {
                    return (
                      <LegacyCard sectioned key={index}>
                        <LegacyStack alignment="center">
                          <LegacyStack.Item fill>
                            <LegacyStack alignment="center">
                              <LegacyStack.Item>
                                <p>{rule.ruleType}</p>
                              </LegacyStack.Item>
                              <LegacyStack.Item fill>
                                <Popover
                                  active={popoverActive}
                                  activator={
                                    <Button
                                      onClick={togglePopoverActive}
                                      textAlign="left"
                                      disclosure={expanded ? "up" : "down"}
                                      fullWidth
                                    >
                                      {addressTypeSelected.length === 0
                                        ? "Select address type"
                                        : addressTypeSelected.join(", ")}
                                    </Button>
                                  }
                                  autofocusTarget="first-node"
                                  onClose={togglePopoverActive}
                                  fullWidth
                                  sectioned
                                >
                                  <ChoiceList
                                    allowMultiple
                                    choices={[
                                      {
                                        label: "PO Box",
                                        value: "PO Box",
                                      },
                                      {
                                        label: "Military: APO",
                                        value: "Military: APO",
                                      },
                                      {
                                        label: "Military: FPO",
                                        value: "Military: FPO",
                                      },
                                      {
                                        label: "Diplomatic: DPO",
                                        value: "Diplomatic: DPO",
                                      },
                                    ]}
                                    selected={addressTypeSelected}
                                    onChange={handleChange}
                                  />
                                </Popover>
                              </LegacyStack.Item>
                            </LegacyStack>
                          </LegacyStack.Item>
                          <LegacyStack.Item>
                            <LegacyStack>
                              <div onClick={() => deleteOneContent(index)}>
                                <Button icon={DeleteIcon} tone="critical"></Button>
                              </div>
                            </LegacyStack>
                          </LegacyStack.Item>
                        </LegacyStack>
                      </LegacyCard>
                    );
                  })}
                  <Popover
                    active={ruleDropDownActive}
                    activator={activator}
                    autofocusTarget="first-node"
                    onClose={toggleRuleDropDownActive}
                  >
                    <div style={{ width: "400px" }}>
                      <ActionList
                        actionRole="menuitem"
                        sections={[
                          {
                            items:
                              state.rules &&
                              state.rules.find((r) => r.ruleType === "Address type")
                                ? [
                                    {
                                      content: "Character limit - coming soon",
                                    },
                                  ]
                                : [
                                    {
                                      content: "Address type",
                                      onAction: () => {
                                        addAddressType()
                                      },
                                    },
                                    {
                                      content: "Character limit - coming soon",
                                    },
                                  ],
                          },
                        ]}
                      />
                    </div>
                  </Popover>
                </FormLayout>
              </LegacyCard>
            </FormLayout>
            <DocSave
              isCreating={isCreating}
              isEditing={isEditing}
              isSubmitting={isSubmitting}
              hasUnsavedChanges={hasUnsavedChanges}
              handleFormSubmit={handleFormSubmit}
              handleCustomContentDelete={handleCustomContentDelete}
              location={location}
            />
          </Form>
        </div>
      </Layout.Section>
    </Layout>
  );
}

export default AddressBlockerForm
